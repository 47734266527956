function Contact() {
  return (
    <div className="container contact">
      <h2 className="main-title text-center">CONTACT</h2>
 
    <div className="row">
      <div className="col-md-4 location-table-cell">
          <table className="table">
            <tbody>
              <tr>
                <th scope="row"><img className="img-fluid" src="marker.png"/></th>
                <td><p className="contact-text">348 New Byhalia Rd #7, Collierville, TN, 38017</p></td>
              </tr>
              <tr>
                <th scope="row"><img className="img-fluid" src="mobile-notch.png"/></th>
                <td><p className="contact-text">(901) 414-2218</p></td>
              </tr>
              <tr>
                <th scope="row"><img className="img-fluid" src="envelope.png"/></th>
                <td><p className="contact-text">hr@codeworksit.com</p></td>
              </tr>
            </tbody>
          </table>
      </div>


      <div className="col-md-8">
        <div className="row">
          <div className="col-md-4 mb-1">
            <input name="name" placeholder="First Name" className="contact-input" />
          </div>

          <div className="col-md-4 mb-1">
            <input name="name" placeholder="Last Name" className="contact-input" />
          </div>
          <div className="col-md-4 mb-1">
            <input
              name="email"
              placeholder="Email"
              className="contact-input"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <textarea
              name="message"
              placeholder="Message"
              className="contact-textarea"
            />
          </div>
        </div>
        <br></br>
        <div className="row">
        <div className="col-md-12">
          <input className="form-btn" type="submit" value="Send Message" />
        </div>
      </div>
      </div>
      <br />

      
    </div>




      

      
      
    </div>
  );
}
export default Contact;
