function Header() {
  return (
    <header>
      <video src="bgvideo.mp4" loop autoPlay muted></video>
      <h1>Codeworks IT</h1>
      <div className="row">
      <p className="header-para">We at Codeworks IT are committed to maximizing global workforce solutions on behalf of its clients.</p>
        {/* <button className="btn" style={{ cursor: "pointer" }}>
          Sign Up
        </button>

        <button className="btn" style={{ cursor: "pointer" }}>
          Log in
        </button> */}
      </div>

      <div className="headerbg"></div>
    </header>
  );
}
export default Header;
