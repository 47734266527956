import Card from "./Card";
function Services() {
  return (
    <div className="container services">
        <h2 className="main-title text-center">SERVICES</h2>
        <div className="card-cover">
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-4 mb-2">
                        <Card title="Cloud Services" img="../card1.png" text="Modernize your IT infrastructure increase storage, improve security, and enhance analytics capabilities with cloud services designed to solve any business challenge while saving money " />
                    </div>
                    <div className="col-md-4 mb-2">
                    <Card title="Managed IT Services" img="../card2.png" text="Managed IT services enable organizations to outsource IT tasks to a vendor as contracted or subscription services. These services can be used to lighten the workload of in-house teams, supplement teams that are unable to meet IT demand, or to replace in-house teams" />
                    </div>
                    <div className="col-md-4 mb-2">
                    <Card title="Data Management & Analytics" img="../card3.png" text="To help enterprises organize critical process correctly. Strategize and improve your business performance by visualizing user data and get complete picture of customers behavior from multiple facts" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 mb-2">
                        <Card title="Quality Assurance" img="../card1.png" text="We perform functional, regression, security, and performance/load testing for customers from all verticals " />
                    </div>
                    <div className="col-md-4 mb-2">
                    <Card title="Data Warehouse Design & Modeling" img="../card2.png" text="We assess and prioritize opportunities for value creation based on careful investment justification and requirements" />
                    </div>
                    <div className="col-md-4 mb-2">
                    <Card title="Cyber Security" img="../card3.png" text="Cybersecurity is more important than ever. Protect your systems, networks, and programs from digital attacks" />
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}
export default Services;
