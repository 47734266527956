import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import 'swiper/css/autoplay';


function Clients() {
  return (
    <div className="container clients">
        <h2 className="main-title text-center">OUR CLIENTS</h2>
        <div className="row swiper-row">
            <div className="col-md-12">
                <Swiper
                breakpoints={{
                    0:{
                        width: 200,
                        slidesPerView: 1
                    },
                    576:{
                        slidesPerView: 2
                    },
                    768:{
                        slidesPerView: 4
                    },
                    992:{
                        slidesPerView: 4
                    },
                    1300:{
                        slidesPerView: 4
                    }
                }}
                    modules={[Navigation, Pagination, Autoplay]}
                    // slidesPerView={4}
                    // navigation
                    speed={1000}
                    autoplay={{ delay: 1000 }}
                    // pagination={{ clickable: true }}
                >
                    <SwiperSlide><img className="img swiper-image" src="clients/amex.png" alt="" /></SwiperSlide>
                    <SwiperSlide><img className="img swiper-image" src="clients/apple.png" alt="" /></SwiperSlide>
                    <SwiperSlide><img className="img swiper-image" src="clients/bcbs.png" alt="" /></SwiperSlide>
                    <SwiperSlide><img className="img swiper-image" src="clients/cognizant.png" alt="" /></SwiperSlide>
                    <SwiperSlide><img className="img swiper-image" src="clients/mastercard.png" alt="" /></SwiperSlide>
                    <SwiperSlide><img className="img swiper-image" src="clients/mphasis.png" alt="" /></SwiperSlide>
                    <SwiperSlide><img className="img swiper-image" src="clients/tesla.png" alt="" /></SwiperSlide>
                    <SwiperSlide><img className="img swiper-image" src="clients/visa.png" alt="" /></SwiperSlide>
                    <SwiperSlide><img className="img swiper-image" src="clients/wipro.png" alt="" /></SwiperSlide>
                </Swiper>
            </div>
        </div>
    </div>
  );
}

export default Clients;