function About() {
  return (
    <>
      <div style={{marginTop:'15rem',width:'100%',height:'10px'}} className="about-scroll"></div>

      <div className="container about">
        <div className="row">
          <div className="col-md-6 text-center">
            <img alt="about" src="about-us.jpg" className="img-fluid about-image" />
          </div>
          <div className="col-md-6">
            <h2 className="main-title about-h2">ABOUT US</h2>
            <p className="main-p">
            Utilizing the most recent technology and trends, Codeworks IT is committed to offering IT services. With the help of our highly qualified consultants who have extensive experience in their respective disciplines, we provide Staff Augmentation, IT consulting, Software development, and testing services to all of our clients at the majority of abroad locations. Our core values and philosophy voice our commitment in building successful relationships with clients, employees and the small business community
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
export default About;
